export default {
	adminMenu_general: "Generelt er MKE den beste",
	adminMenu_professionalAutosignatures: "Autosignaturer",
	adminMenu_patientStates: "Pasientstatus",
	adminMenu_unprocessedPatients: "Pasienter uten f.nr-oppdatering",
	adminMenu_letterTemplates: "Brevmaler",
	adminMenu_pathwayTypes: "Saksmapper",
	adminMenu_financialAccounts: "Kontonummer",
	adminMenu_kleNumbers: "KLE-nummer",
	adminMenu_subjectTemplates: "Korrespondanseemne",
	adminMenu_grant_offer_portal_suppliers: "Leverand\u00F8rer fra Tilbudsportalen",
	adminMenu_homeAdjustment: "Boligtilpasninger",
	adminMenu_suppliers: "Leverand\u00F8rer",
	adminMenu_logViewer: "Loggviser",
	adminMenu_locations: "Lokaler",
	adminMenu_mobileProfile: "Mobilkonfigurasjon",
	adminMenu_organizations: "Organisasjoner, brukere og sikkerhet",
	adminMenu_assignments: "Oppgaver",
	adminMenu_assignment_pause_reasons: "Oppgaver - \u00C5rsaker til pause",
	adminMenu_paragraphs: "Paragrafer",
	adminMenu_physicalResources: "Ressurser",
	adminMenu_physicalResourceTypes: "Ressurstyper",
	adminMenu_citizenCompensationRate: "Satser",
	adminMenu_professionalLocks: "Se l\u00E5ste brukere",
	adminMenu_formDefinitions: "Skjemaer",
	adminMenu_formDefinitions_new: "Skjemaer new",
	adminMenu_professionalJobs: "Stillingsbetegnelser",
	adminMenu_sites: "Steder",
	adminMenu_hospitalStay: "Sykehusopphold",
	adminMenu_tags: "Tags",
	adminMenu_shifts: "Vakttyper",
	adminMenu_programPathways: "Saksmapper",
	adminMenu_catalogGrants: "Tjenestekatalog",
	adminMenu_finance: "Økonomi MKE 5",
	adminMenu_payoutRecipients: "Utbetalingsmottakere",
	adminMenu_payoutFinancialAccounts: "Kontonummer for utbetaling",
	adminMenu_calendar: "Kalender",
	adminMenu_eventTypes: "Avtaletyper",
	adminMenu_plannedGrantEventTypes: "Bes\u00F8kstyper",
	adminMenu_groupEventSubTypes: "Kalenderavtaletyper",
	adminMenu_groupEventRegistrationStatus: "Kalenderavtalestatus",
	adminMenu_groupRegisteredGrantStatuses: "Levert tjenestestatus (Avtaler)",
	adminMenu_registeredGrantStatuses: "Levert tjenestestatus (Bes\u00F8k)",
	adminMenu_groupPlannedGrantStatuses: "Planlagt tjenestestatus (Avtaler)",
	adminMenu_plannedGrantStatuses: "Planlagt tjenestestatus (Bes\u00F8k)",
	adminMenu_resourcePlannedGrantStatuses: "Planlagt tjenestestatus (Ressurser)",
	adminMenu_resourceEventTypes: "Ansattavtaletype",
	adminMenu_resourceEventStatuses: "Ansattavtalestatus",
	adminMenu_registrationStatus: "Registreringsstatus",
	adminMenu_calendarRoutes: "Ruter",
	adminMenu_contacts: "Eksterne kontakter",
	adminMenu_organizationContacts: "Kontaktorganisasjoner",
	adminMenu_organizationContactTypes: "Kontaktorganisasjonstyper",
	adminMenu_professionalContacts: "Kontaktpersoner",
	adminMenu_professionalContactTypes: "Kontaktpersontyper",
	adminMenu_relativeContactTypes: "P\u00E5r\u00F8rendetyper",
	adminMenu_medicin: "Medisin",
	adminMenu_internalDrugs: "Internt opprettede preparater",
	adminMenu_inventoryManagement: "Lager",
	adminMenu_reports: "Rapporter",
	adminMenu_measurements: "M\u00E5linger",
	adminMenu_hclProducts: "Produkter",
	adminMenu_hclHmiCatalogues: "HMI-kataloger",
	adminMenu_hclDepots: "Depoter",
	adminMenu_hclSuppliers: "Forhandlere",
	adminMenu_hclProductSuppliers: "Produktleverand\u00F8rer",
	adminMenu_hclLabelDesign: "Etikettdesign",
	adminMenu_hclCars: "Biler",
	adminMenu_hclDrivingZones: "Kj\u00F8reomr\u00E5der",
	adminMenu_hclDeliveryTypes: "Leveringstyper",
	adminMenu_hclMinimumInventory: "Minimumsbeholdning",
	adminMenu_hclOpslag: "Oppslag",
	adminMenu_hclAutomation: "Automatisering",
	adminMenu_hclProductItemsOwners: "Eier",
	adminMenu_patientCategory: "Pasientkategorier"
};
